import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ProgressService } from 'src/app/services/progress.service';
import { map, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Arabic } from 'flatpickr/dist/l10n/ar';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare let moment: any;
declare let $: any;

@Component({
  selector: 'admin-layout-receptionist',
  templateUrl: './receptionist.component.html',
  styleUrls: ['./receptionist.component.scss'],
})
export class ReceptionistComponent implements OnInit {
  total = 0;
  rec: any;
  isExcel: boolean = false;
  transaction: boolean = false;
  transactions: any;
  request: boolean = false;
  rev: boolean = true;
  requests: Array<any> = [];
  pagination: any;
  pagination_transactions: any = {};
  action: any = {};
  from = null;
  to = null;
  from_time = null;
  to_time = null;
  payment_status = null;
  patient_id = null
  correspondent_id = null
  id = null;
  isTax = false;
  patients: any = [];
  correspondents: any = [];
  payment_methods: any = [];
  withTests = false;
  excel = false;
  excel2 = false;
  dropdownSettings: IDropdownSettings;
  dropdownSettings2: IDropdownSettings;
  payment_method_filter = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public data: DataService,
    public router: Router,
    public api: ApiService,
    public progress: ProgressService
  ) {}

  change() {
    console.log(this.from, this.to);
  }

  getPatients() {
    this.api.Common.patients.get({}).subscribe((res) => {
      this.patients = res['data'];
    });
  }


  getPaymentMethods(){
    this.api.Common.Get.payment_methods().subscribe((res) => {
      this.payment_methods = res;
    })
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'name',
    };

    this.api.Common.Get.correspondents().subscribe((res) => {
      this.correspondents = res;
    });

    this.getPatients();

    this.data.infoAssigned.subscribe((isAssigned) => {
      if (isAssigned) {
        this.isTax = this.data.info['settings']['isTax'];
      }
    });

    // this.from = moment().subtract(7, 'days').format('DD-MM-YYYY');
    this.from = moment().format('DD-MM-YYYY');
    this.to = moment().format('DD-MM-YYYY');
    this.from_time = '00:00';
    this.to_time = '23:59';
    this.getPaymentMethods();

    this.activatedRoute.params.subscribe((param: any) => {
      this.id = param.id || this.data.me.id;
      this.getReceptionist(this.id, true);
      this.getRequests(this.id, 1);
      this.getTransactions(this.id, 1);
    });
  }

  getReceptionist(id = this.id, init = false) {
    // this.progress.on();
    setTimeout(
      () =>
        this.api.Admin.receptionists
          .get_one(id, {
            min_date: this.from.split('-').reverse().join('-'),
            max_date: this.to.split('-').reverse().join('-'),
            from_time: this.from_time,
            to_time: this.to_time,

            page: 1,
          })
          .pipe(
            map((res) => res['data']),
            tap((res) => {
              this.progress.off();
              this.rec = res;
              if (init) {
                setTimeout(() => {
                  $('#fromPicker').flatpickr({
                    dateFormat: 'd-m-Y',
                    defaultDate: moment()
                      // .subtract(7, 'days')
                      .format('DD-MM-YYYY'),
                    locale: this.data.language == 'arabic' ? Arabic : null,
                    position: 'auto center',
                  });

                  $('#toPicker').flatpickr({
                    dateFormat: 'd-m-Y',
                    defaultDate: moment().format('DD-MM-YYYY'),
                    locale: this.data.language == 'arabic' ? Arabic : null,
                    position: 'auto center',
                  });

                  ['#from_time' , '#to_time'].forEach(id => {
                    $(id).flatpickr({
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      locale: this.data.language == "arabic" ? Arabic : null,
                      position: "auto center",
                    });
                  })

                }, 0);
              }
              console.log(res);
            })
          )
          .subscribe((res) => {}),
      0
    );
  }
  getTransactions(id = this.id, page = 1) {
    setTimeout(
      () =>
        this.api.Admin.receptionists
          .transactions(id, {
            min_date: this.from.split('-').reverse().join('-'),
            max_date: this.to.split('-').reverse().join('-'),
            from_time: this.from_time,
            to_time: this.to_time,
            payment_method_id: this.payment_method_filter,
            page,
          })
          .subscribe((res) => {
            // `?page=${page}&min_date=${}&max_date=${}&from_time=${this.from_time}&to_time=${this.to_time}`
            this.transactions = res['data'];
            this.total = res['total_transactions'];
            this.pagination_transactions = this.data.extract_pagination(res);
          }),
      0
    );
  }




  getRequests(id = this.id, page = 1) {
    let params = {
      page,
      min_date: this.from.split('-').reverse().join('-'),
      max_date: this.to.split('-').reverse().join('-'),
      from_time: this.from_time,
      to_time: this.to_time,
    };

    this.patient_id && (params['patient'] = this.patient_id);
    this.payment_status && (params['payment_status'] = this.payment_status);
    this.correspondent_id && (params['correspondent'] = this.correspondent_id);
    // let withTests = this.withTests == true ? 1 : 0
    // let secQuery = '&withTests='+withTests
    // if(this.correspondent_id[0]['id'] != null && this.correspondent_id[0]['id'] != 'null'){
    //   secQuery += '&correspondent='+this.correspondent_id[0]['id']
    // }
    setTimeout(
      () =>
        this.api.Admin.receptionists.requests(id, params).subscribe((res) => {
          this.requests = res['data'];
          this.total = res['total'];
          this.pagination = this.data.extract_pagination(res);
        }),
      0
    );
  }

  print_transaction(id = this.id, init = false) {
    let excel2 = this.excel2 == true ? 1 : 0;
    setTimeout(
      () =>
        this.api.Common.printables.receptionists
          .transaction(
            id,
            `?min_date=${this.from
              .split('-')
              .reverse()
              .join('-')}&max_date=${this.to
              .split('-')
              .reverse()
              .join('-')}&from_time=${this.from_time}&to_time=${
              this.to_time
            }&excel=${excel2}&payment_method_id=${this.payment_method_filter}`
          )
          .subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
            console.log('hello people');
          }),
      0
    );
  }

  print_requests_pdf(id = this.id, init = false) {
    let withTests = this.withTests == true ? 1 : 0;
    let excel = this.excel == true ? 1 : 0;
    let secQuery = '&withTests=' + withTests + '&excel=' + excel;
    // if (
    //   this.correspondent_id[0]['id'] != null &&
    //   this.correspondent_id[0]['id'] != 'null'
    // ) {
    //   secQuery += '&correspondent=' + this.correspondent_id[0]['id'];
    // }
    // if (
    //   this.patient_id[0]['name'] != null &&
    //   this.patient_id[0]['name'] != 'null'
    // ) {
    //   secQuery += '&patient=' + this.patient_id[0]['name'];
    // }
    // if (this.payment_status != null && this.payment_status != 'null') {
    //   secQuery += '&payment_status=' + this.payment_status;
    // }
    setTimeout(
      () =>
        this.api.Common.printables.receptionists
          .requests(
            id,
            `?min_date=${this.from
              .split('-')
              .reverse()
              .join('-')}&max_date=${this.to
              .split('-')
              .reverse()
              .join('-')}&from_time=${this.from_time}&to_time=${this.to_time}` +
              secQuery
          )
          .subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
            console.log('hello people');
          }),
      0
    );
  }

  sendRequest() {
    this.rev ? this.getReceptionist() : '';
    this.request ? this.getRequests() : '';
    this.transaction ? this.getTransactions() : '';
  }
}
